/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
(function($) {
    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                var currentSlider;
                // JavaScript to be fired on all pages
                var windowHeight = $(window).height();
                var windowWidth = $(window).width();
                var curPrevArrow = $('#prevSelector');
                var curNextArrow = $('#nextSelector');
                var contentWrapperH;
                var imgWrapper;
                $('#nav-icon4').click(function() {
                    $(this).toggleClass('open');
                    $('header').toggleClass('shown');
                    $('#navigation-overlay').toggleClass('shown');
                    $('body').toggleClass('fixed');
                });
                // $('#hamburger').on('click', function(){
                //   $('#navigation-overlay').addClass('shown');
                // });
                // $('#close-menu').on('click', function(){
                //   $('#navigation-overlay').removeClass('shown');
                // });
                $('#mob-search-trigger').on('click', function() {
                    $('.search-input').toggleClass('open');
                });
                $(document).on('click', function(e) {
                    if ($(e.target).closest('.fa-search').length) {
                        $('#search-form').addClass("open");
                        $('.searchfield-cont').addClass('open');
                    } else if (!$(e.target).closest('#search-form').length) {
                        $('#search-form').removeClass("open");
                        $('.searchfield-cont').removeClass('open');
                    }
                });
                var contentWrapperWidth;

                function gallerySlidesWidth() {
                    contentWrapperWidth = $('.content-wrapper').width();
                }
                var width = $('.content-wrapper.gallery .container').width();
                var sliderWidth = 640;
                var slider = null;
                var gallerySlideWidth = 300;
                var maxSlides = 4;
                if (windowHeight < 1024) {
                    gallerySlideWidth = 250;
                }
                // if(windowHeight < 1024 && windowWidth < 1300){
                //   maxSlides = 3;
                //   contentWrapperWidth = $('.content-wrapper').width();
                //   gallerySlideWidth = contentWrapperWidth / 2;
                // }else if(windowHeight < 1024 && windowWidth > 1300){
                //   maxSlides = 4;
                //   contentWrapperWidth = $('.content-wrapper').width();
                //   gallerySlideWidth = contentWrapperWidth / 4;
                // }
                var slider_config;
                var slider_config_references;
                var refContWidth;
                var maxSlidesReferenz;
                var partnerSlider;
                var gallerySlider;
                var bxslider = $('.bxslider');

                function checkSlider() {
                    contentWrapperH = $('.content-wrapper').height();
                    windowHeight = $(window).height();
                    windowWidth = $(window).width();
                    // console.log(windowHeight);
                    // console.log(currentSlider);
                    // if(windowHeight < 1024){
                    //   gallerySlideWidth = 250;
                    //     if(currentSlider != null){
                    //       $('#gallery-bxslider .slider').width(250);
                    //     }
                    // }        

                    // console.log(currentSlider);
    
                    if ((windowWidth >= 768) || (contentWrapperH >= 943)) {
                        // if(currentSlider != null){
                        //   currentSlider.reloadSlider();
                        // }
                        // if(partnerSlider != null){
                        //   partnerSlider.reloadSlider();
                        // }
                    } else {
                        // if(partnerSlider != null){
                        //   partnerSlider.destroySlider();
                        // }
                    }
                    // currentSlider.reloadSlider();
                }

                function sliderConfig() {
                    contentWrapperWidth = $('.get-width .container').width();
                    refContWidth = $('#refCont').width();
                    if (windowWidth >= 1300) {
                        maxSlides = 4;
                        maxSlidesReferenz = 3;
                        contentWrapperWidth = $('.get-width .container').width();
                        gallerySlideWidth = contentWrapperWidth / 4;
                    } else if (windowWidth < 1300) {
                        maxSlides = 3;
                        maxSlidesReferenz = 2;
                        contentWrapperWidth = $('.get-width .container').width();
                        gallerySlideWidth = contentWrapperWidth / 3;
                    }
                    // Gallery Slider
                    slider_config = {
                        minSlides: 1,
                        maxSlides: 4,
                        shrinkItems: true,
                        slideSelector: 'div.slide',
                        slideWidth: gallerySlideWidth,
                        startSlide: 0,
                        responsive: true,
                        controls: true,
                        pager: true,
                        randomStart: false,
                        touchEnabled: false,
                        slideMargin: 40,
                        nextSelector: curNextArrow,
                        prevSelector: curPrevArrow,
                        nextText: '<i class="arrow fa fa-angle-right fa-3x" aria-hidden="true"></i>',
                        prevText: '<i class="arrow fa fa-angle-left fa-3x" aria-hidden="true"></i>',
                        hideControlOnEnd: true,
                        autoHidePager: true,
                        onSliderResize: function(e) {}
                    };
                    // Refferenzen Slider
                    slider_config_references = {
                        minSlides: 1,
                        maxSlides: 4,
                        shrinkItems: true,
                        slideSelector: 'div.slide',
                        slideWidth: contentWrapperWidth / maxSlidesReferenz,
                        startSlide: 0,
                        responsive: true,
                        controls: true,
                        pager: true,
                        randomStart: false,
                        touchEnabled: false,
                        slideMargin: 10,
                        nextText: '<i class="arrow fa fa-angle-right fa-3x" aria-hidden="true"></i>',
                        prevText: '<i class="arrow fa fa-angle-left fa-3x" aria-hidden="true"></i>',
                        hideControlOnEnd: true,
                        autoHidePager: true,
                        onSliderResize: function() {}
                    };
                    if (bxslider.attr("id") === "partner-bxslider") {
                        partnerSlider = $('#partner-bxslider').bxSlider(slider_config);
                        currentSlider = partnerSlider;
                    } else if (bxslider.attr("id") === "gallery-bxslider") {
                        currentSlider = $('#gallery-bxslider').bxSlider(slider_config);
                        partnerSlider = null;
                    } else if (bxslider.attr("id") === "groups-bxslider") {
                        currentSlider = $('#groups-bxslider').bxSlider(slider_config);
                        partnerSlider = null;
                    } else if (bxslider.attr("id") === "references-bxslider") {
                        currentSlider = $('#references-bxslider').bxSlider(slider_config_references);
                        partnerSlider = null;
                        refContWidth = $('#refCont').width();
                    } else {
                        partnerSlider = null;
                        currentSlider = null;
                    }
                    // currentSlider.reloadSlider();
                    checkSlider();
                }


                // FILTER BY CONTACT PERSONS
                var curFilter;
                $(document).on('click', '.filter-radio input', function() {

                    var link = null;
                    curFilter = $(this).val();
                    if (curFilter !== '') {
                        link = $('#partner-bxslider').attr('data-link') + '?curterm=' + curFilter;
                    } else {
                        link = $('#partner-bxslider').attr('data-link');
                    }
                    $.get(link, function(data) {
                        $('#partner-bxslider').html($(data).find('#partner-bxslider').html());
                        // $('#partner-bxslider')
                        // var partnerSlider = $('#partner-bxslider').bxSlider(slider_config);
                        currentSlider.reloadSlider();
                        // console.log(currentSlider);
                        $('#mobile-partner').html($(data).find('#mobile-partner').html());
                    });
                });
                $('.slide-with-gallery').on('click', function(event) {
                    event.preventDefault();
                    var gallery = $(this).data('gallery');
                    $(gallery).magnificPopup({
                        delegate: 'a',
                        type: 'image',
                        gallery: {
                            enabled: true
                        }
                    }).magnificPopup('open');
                });
                $('.mygallery').each(function() { // the containers for all your galleries
                    $(this).magnificPopup({
                        delegate: 'a', // the selector for gallery item
                        type: 'image',
                        gallery: {
                            enabled: true
                        }
                    });
                });
                $('.popup').each(function() { // the containers for all your galleries
                    $(this).magnificPopup({
                        delegate: 'a', // the selector for gallery item
                        type: 'image',
                        gallery: {
                            enabled: true
                        }
                    });
                });
                var curclass;
                var curSlide;
                $(".subpages-wrapper.gallery li").hover(function() {
                    $(this).delay(500).queue(function() {
                        curclass = $(this).attr('class');
                        curSlide = document.getElementById(curclass);
                        $(curSlide).addClass('hover');
                    });
                }, function() {
                    $(this).finish();
                    $(curSlide).removeClass('hover');
                });
                $(document).on('click', '[data-toggle="lightbox"]', function(event) {
                    event.preventDefault();
                    $(this).ekkoLightbox();
                });
                $(window).on('resize', function() {
                    if (currentSlider) {
                        currentSlider.reloadSlider();
                    }
                    // if(partnerSlider != null){
                    //   partnerSlider.reloadSlider();
                    // }
                });


                setTimeout(function() {
                    sliderConfig();
                }, 100);


            },
            finalize: function() {

                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Geschichte page
        'geschichte': {
            init: function() {
                var newTop;
                var curYear;
                var imagebox;
                var newCurYear;
                var curbox;
                var position;
                var nextbox;
                var mousewheelevt = (/Firefox/i.test(navigator.userAgent)) ? "DOMMouseScroll touchmove" : "mousewheel touchmove"; //FF doesn't recognize mousewheel as of FF3.x
                var minPosition;
                var maxPosition;
                var mindingMousewheel = true;

                function setPosition(position) {
                    position = position;
                    if (position > 0) {
                        position = -Math.abs(position);
                    }
                    $("#timeline-content .inner .scroll").animate({
                        top: position
                    }, 1000, function() {
                        //$('#timeline-content .inner .scroll').css('top', position+'px');
                        //$('#timeline-content .inner').bind('mousewheel');
                        mindingMousewheel = true;
                    });
                }

                function SwipeUp() {
                    if (position === minPosition) {
                        mindingMousewheel = true;
                    } else {
                        $('.box.active').removeClass('active');
                        $('.box.prev').addClass('active');
                        position = $('.box.prev').data('offset');
                        setPosition(position);
                        checkPoint($('.box.prev').data('year'));
                        checkbox();
                    }
                }

                function SwipeDown() {
                    if (position === maxPosition) {
                        mindingMousewheel = true;
                    } else {
                        $('.box.active').removeClass('active');
                        $('.box.next').addClass('active');
                        position = $('.box.next').data('offset');
                        setPosition(position);
                        checkPoint($('.box.next').data('year'));
                        checkbox();
                    }
                }

                function checkTouchDevice() {
                    return 'ontouchstart' in document.documentElement;
                }
                if (checkTouchDevice()) {
                    $(window).on("orientationchange", function(event) {
                        location.reload();
                    });
                    // Mobile device
                    $(window).on('touchstart', function(e) {
                        // console.log('touchstart');
                        var swipe = e.originalEvent.touches,
                            start = swipe[0].pageY;
                        $(this).one('touchmove', function(e) {
                            // console.log(e);
                            var contact = e.originalEvent.touches,
                                end = contact[0].pageY,
                                distance = end - start;
                            // console.log(distance);
                            if (distance < -10) {
                                console.log('up');
                                SwipeDown();
                            }
                            if (distance > 10) {
                                console.log('down');
                                SwipeUp();
                            }
                        }).one('touchend', function() {
                            $(this).off('touchmove touchend');
                        });
                    });
                } else {
                    // Desktop
                    $('#timeline-content .inner').bind(mousewheelevt, function(e) {
                        if (!mindingMousewheel) return;
                        var evt = window.event || e; //equalize event object     
                        evt = evt.originalEvent ? evt.originalEvent : evt; //convert to originalEvent if possible               
                        var delta = evt.detail ? evt.detail * (-40) : evt.wheelDelta; //check for detail first, because it is used by Opera and FF
                        mindingMousewheel = false;
                        //  $('#timeline-content .inner').unbind('mousewheel');
                        // console.log('curpos: ' + position);
                        if (delta > 0) {
                            //scroll up
                            // console.log('up');
                            SwipeUp()
                        } else {
                            // console.log('down');
                            SwipeDown();
                        }
                    });
                }

                function checkbox() {
                    if ($('.box.active').next('.box')) {
                        $('.box').removeClass('next');
                        $('.box.active').next('.box').addClass('next');
                    }
                    if ($('.box.active').prev('.box')) {
                        $('.box').removeClass('prev');
                        $('.box.active').prev('.box').addClass('prev');
                    }
                }
                $('.point').on('click', function() {
                    //$('#timeline-content .inner .scroll').css('top', '-20px');
                    $('#timeline-content .inner .box').removeClass('active');
                    $('.point').removeClass('active');
                    $(this).addClass('active');
                    curYear = $(this).data('year');
                    curbox = $('#y' + curYear);
                    curbox.addClass('active');
                    position = curbox.data('offset');
                    checkbox();
                    setPosition(position);
                });

                function checkPoint(curYear) {
                    $('.point').removeClass('active');
                    $('.timeline').find("[data-year='" + curYear + "']").addClass('active');
                    newCurYear = $('#timeline-content .inner').find("[data-year='" + curYear + "']");
                }

                function initTimeline() {
                    position = 0;
                    $('.box').each(function() {
                        var boxposition = $(this).position();
                        boxposition = boxposition.top;
                        $(this).attr('data-offset', boxposition); // sets 
                    });
                    minPosition = $('.box').first().data('offset');
                    maxPosition = $('.box').last().data('offset');
                    $('.box').first().addClass('start');
                    $('.box').last().addClass('end');
                    checkbox();
                }
                initTimeline();
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // Contact persons 
        'kontakt': {
            init: function() {},
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        }
    };
    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';
            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');
            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });
            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };
    // Load Events
    $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.